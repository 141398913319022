.App {
  width: "100%";
  height: "100%";
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.test {
  text-align: center;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  background-color: #f1faf5;
  height: 100%;
  width: 100%;
}

.header {
  height: 100%;
  width: 32vmin;
}
@media only screen and (max-width: 800px) {
  .header {
    height: 32vmin;
    width: 300%;
  }
}
